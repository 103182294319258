import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useCurrentArea } from "src/core/contexts";
import { useLightingIoTTranslation } from "../../utils/translation";
import { setTextFieldValue, toStringValue } from "src/core/utils/formik";
import { useAddControllerToLuminaireFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTAddControllerToLuminaire";
import type { LightingIoT_LuminaireModel } from "src/graphql/types";

export interface LuminaireControllerFormProps {
  onClose: () => void;
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireControllerForm: React.FC<LuminaireControllerFormProps> = (
  props
) => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();

  const [addController] = useAddControllerToLuminaireFromLightingIoTMutation({
    update: (cache, result) => {
      if (
        result.errors ||
        !result.data?.LightingIoT_addControllerToLuminaire.isSuccess
      )
        return;
      cache.evict({
        fieldName: "LightingIoT_luminaireById",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (values: any): Promise<void> => {
    const { data } = await addController({
      variables: {
        input: {
          areaId: area.id,
          luminaireId: props.luminaire.id,
          luminaireVersion: props.luminaire.version,
          controllerId: formik.values.id,
        },
      },
    });

    const res = data?.LightingIoT_addControllerToLuminaire;
    if (res?.isSuccess) {
      toast.success(t("notification.controller_added"));
      props.onClose();
    } else {
      res?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<{ id: string }>({
    initialValues: { id: null as any },
    validationSchema: yup.object().shape({
      id: yup.string().required().fromFormik().min(11).max(11),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_add_controller.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.TextInput
            wide
            id="id"
            name="id"
            disabled={formik.isSubmitting}
            error={formik.touched.id && formik.errors.id}
            label={t("id")}
            value={toStringValue(formik.values.id)}
            onChange={setTextFieldValue(formik, "id")}
          />
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminaireControllerForm;
